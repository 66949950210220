document.addEventListener('DOMContentLoaded', () => {
  // Add event listeners to elements with class "dropdown"
  const dropdowns = document.querySelectorAll('.has-dropdown');

  dropdowns.forEach((dropdown) => {
    // eslint-disable-next-line func-names
    dropdown.addEventListener('mouseenter', function () {
      this.classList.add('open');
    });

    // eslint-disable-next-line func-names
    dropdown.addEventListener('mouseleave', function () {
      this.classList.remove('open');
    });
  });
});
