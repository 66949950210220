import FormValidation from '@/js/components/forms/form_validation';

class Home {
  constructor() {
    this.dom = {
      subscriberForm: document.getElementById('subscriber-form'),
      alertScroll: document.querySelector('.js-alert-scroll:not(.d-none)'),

    };

    this.data = {};

    this.components = {
      subscriberFormValidation: null,
    };

    this.mount();
  }

  mount() {
    if (this.dom.subscriberForm) {
      this.components.subscriberFormValidation = new FormValidation(this.dom.subscriberForm);
    }
    if (this.dom.alertScroll) {
      this.dom.alertScroll.scrollIntoView({ behavior: 'smooth' });
    }
  }
}

if (document.getElementById('page-home')) {
  // eslint-disable-next-line no-new
  new Home();
}
