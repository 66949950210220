/* global google */

import markerIcon from '@/img/marker.png';
import InfoWindow from './infoWindow';

export default class Marker {
  /**
   * create Google Maps marker
   * https://developers.google.com/maps/documentation/javascript/examples/marker-simple
   * https://developers.google.com/maps/documentation/javascript/reference/marker
   * @param options {Object}
   * @param infoWindowContent {String}
   */
  constructor(options, infoWindowContent = null) {
    // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerOptions
    const defaultOptions = {
      animation: google.maps.Animation.DROP,
      clickable: true,
      icon: {
        url: markerIcon,
        scaledSize: new google.maps.Size(32, 44),
      },
    };

    this.data = {
      marker: null,
      options: { ...defaultOptions, ...options },
      infoWindowContent,
    };

    this.components = {
      infoWindow: null,
    };

    this.events = {
      onClick: null,
    };

    this.mount();
  }

  // init marker
  mount() {
    // create instance
    this.data.marker = new google.maps.Marker(this.data.options);

    // attach infoWindow is content is passed
    if (this.data.infoWindowContent) {
      // create infoWindow instance
      this.components.infoWindow = new InfoWindow({ content: this.data.infoWindowContent });

      // store event
      this.events.onClick = this.onClick.bind(this);

      // listen to event
      this.data.marker.addListener('click', this.events.onClick);
    }
  }

  // marker has been clicked, open infoWindow
  onClick() {
    this.components.infoWindow.open(this.data.options.map, this.data.marker);
  }
}
