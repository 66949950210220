import axios from 'axios';
import { rootWithLanguage } from '@/js/utils/paths';

export function getCalendarDates() {
  return axios.get(`${rootWithLanguage}evenementen`, {
    params: {
      action: 'calendar-dates',
    },
  });
}

export default {
  getCalendarDates,
};
